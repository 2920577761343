import { Avatar, Grid, Icon } from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { BsCameraVideoFill, BsEmojiSmile, BsFillMicFill } from "react-icons/bs";
import { IoMdCall } from "react-icons/io";
import { BiDotsVertical, BiPhone } from "react-icons/bi";
import { FiVideo, FiVideoOff } from "react-icons/fi";
import { AiOutlineAudioMuted, AiOutlineAudio } from "react-icons/ai";
import "./CallPage.scss";
import pin_icon from "../../assets/icons/pin-icon.svg";
import send_icon from "../../assets/icons/send-icon.svg";
import { MdCallEnd, MdSend } from "react-icons/md";
import io from "socket.io-client";
import { useCookies } from "react-cookie";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "./../../context/AuthContext";
import { VideoCallContext } from "../../context/videoCallContext";
import testVideo from "../../assets/video/test-video.mp4";
var RecordRTC = require('recordrtc');

// import { useLocation } from "react-router-dom";
function CallPage() {
  // const { name, callAccepted, myVideo, userVideo, callEnded, stream, call } =
  //   useContext(VideoCallContext);
  const {
    name,
    callAccepted,
    myVideo,
    userVideo,
    callEnded,
    remoteList,
    stream,
    call,
    mapHash,
    setCallButtonClicked,
    setIncomingCall,
    dialing,
    callButtonClicked,
    endVideoCallFunction,
    toggleVideoFunction,
    videoPaused,
    toggleAudioFunction,
    audioPaused
  } = useContext(VideoCallContext);

  // console.log("remoteList", remoteList);
  // console.log("myVid", myVideo);
  const location = useLocation();
  useEffect(() => {
    setCallButtonClicked(true);
    // if (!callButtonClicked) {
    //   endVideoCallFunction();
    // }
  }, [location]);

  return (
    // <div className="CallPage">
    //   <div className="close-button" onClick={() => setCallButtonClicked(false)}>
    //     Close
    //   </div>
    //   <div>
    //     <video autoPlay={true} controls={false} muted ref={myVideo} />
    //     {/* {stream && (
    //       <div>
    //         <div item xs={12} md={6}>
    //           <p variant="h5"  >
    //             {name || "Name"}
    //           </p>
    //           <video playsInline muted ref={myVideo} autoPlay={true} />
    //         </div>
    //       </div>
    //     )} */}
    //     {/* {mapHash(remoteList, (remote, index) => ( */}
    //     <div>
    //       <div item xs={12} md={6}>
    //         <p variant="h5"  >
    //           {call.name || "Name"}
    //         </p>
    //         <video ref={remoteList} controls={false} autoPlay />
    //       </div>
    //     </div>
    //     {/* ))} */}
    //   </div>
    //   {/* </div>

    //   <div className="bottom-section">
    //     <div className="icon-container">
    //       <img src={pin_icon} alt="send" />
    //       <BsEmojiSmile />
    //     </div>
    //     // </div>
    //   )}
    //   {callAccepted && !callEnded && (
    //     // <div>
    //     <div className="video-container">
    //       <p className="name"  >
    //         {call.name || "Name"}
    //       </p>
    //       <video playsInline ref={remoteList} autoPlay />
    //       <CallButton
    //         stream={stream}
    //         callAccepted={callAccepted}
    //         callEnded={callEnded}
    //       />
    //     </div>
    //     // </div>
    //   )}
    //   {/* </div> */}
    // </div>
    <div className="CallPage">
      {/* <div> */}
      {/* <video autoPlay={true} controls muted ref={myVideo} /> */}
      {dialing ? (
        <div className="dialing-container">
          <Avatar />
          <b>Calling......</b>
          <p> Receiver Name</p>
          <div>
            <button
              type="button"
              className="end-call"
              onClick={() => {
                setCallButtonClicked(false);
              }}
            >
              <MdCallEnd />
            </button>
          </div>
        </div>
      ) : (
        <div className="video-container">
          <div className="video-frame">
            {/* <p className="name">{name || "You"}</p> */}
            {/* <video playsInline muted ref={myVideo} autoPlay={true} /> */}
            <video muted controls={false} autoPlay={true} ref={myVideo} />
          </div>
          <div className="video-frame">
            {/* <p className="name">{name || "Waiting for other party..."}</p> */}
            <video controls={false} autoPlay={true} ref={remoteList} />
          </div>
          <CallButton
            stream={stream}
            callAccepted={callAccepted}
            callEnded={callEnded}
            setCallButtonClicked={setCallButtonClicked}
            endVideoCallFunction={endVideoCallFunction}
            toggleVideoFunction={toggleVideoFunction}
            videoPaused={videoPaused}
            toggleAudioFunction={toggleAudioFunction}
            audioPaused={audioPaused}
          />
        </div>
      )}
      {/* {stream && (
        // <div>
        <div className="video-container">
          <p className="name"  >
            {name || "Name"}
          </p>
          <video playsInline muted ref={myVideo} autoPlay={true} />
          <CallButton
            stream={stream}
            callAccepted={callAccepted}
            callEnded={callEnded}
          />
        </div>
        // </div>
      )}
      {callAccepted && !callEnded && (
        // <div>
        <div className="video-container">
          <p className="name"  >
            {call.name || "Name"}
          </p>
          <video playsInline ref={remoteList} autoPlay />
          <CallButton
            stream={stream}
            callAccepted={callAccepted}
            callEnded={callEnded}
          />
        </div>
        // </div>
      )} */}
    </div>
  );
}

export default CallPage;

function CallButton({
  stream,
  callAccepted,
  callEnded,
  setCallButtonClicked,
  endVideoCallFunction,
  toggleVideoFunction,
  videoPaused,
  toggleAudioFunction,
  audioPaused
}) {
  return (
    <div className="CallButton">
      {stream && (
        <>
          {" "}
          <button 
          onClick={() => {
            toggleAudioFunction();
          }}
          type="button" className="mic">
            { audioPaused ? (
              <AiOutlineAudioMuted />
            ) : (
              <AiOutlineAudio />
            )}
          </button>
          <button 
            type="button"
            onClick={() => {
              toggleVideoFunction();
            }}
            className="voice">
            {videoPaused ? (
              <FiVideoOff />
            ) : (
              <FiVideo />
            )}
          </button>
          {/* <button type="button" className="pick-call">
            <IoMdCall />
          </button> */}
          <button
            type="button"
            className="end-call"
            onClick={() => {
              endVideoCallFunction();
            }}
          >
            <MdCallEnd />
          </button>
        </>
      )}
      {callAccepted && !callEnded && (
        <button type="button" className="end-call">
          <MdCallEnd />
        </button>
      )}
    </div>
  );
}

export function VideoCallCardHover({ setIncomingCall }) {
  const { setCallButtonClicked } = useContext(VideoCallContext);
  return (
    <div className="VideoCallCardHover">
      <div className="card-container">
        <div className="name">Caller Name</div>
        <div className="buttons">
          {/*comment link when using the video overlay instead  */}
          <Link to="/call">
            {" "}
            <button
              className="pick"
              onClick={() => {
                setIncomingCall(false);
                setCallButtonClicked(true);
              }}
            >
              <IoMdCall />
            </button>{" "}
          </Link>

          <button className="end" onClick={() => setIncomingCall(false)}>
            <MdCallEnd />
          </button>
        </div>
      </div>
    </div>
  );
}
