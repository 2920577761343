import React, { useContext, useEffect, useState } from "react";
import {
  NavLink,
  useLocation,
  useParams,
  Link,
  useNavigate,
} from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./DashboardSideNav.scss";
import { DashboardSideNavLinks } from "./navLink";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import {
  BsArrowLeftShort,
  BsArrowRightShort,
  BsBellFill,
  BsFillArrowLeftSquareFill,
} from "react-icons/bs";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Avatar, Icon } from "@chakra-ui/react";
import Logo from "../../assets/images/logo.png";
import { ProjectsContext } from "../../context/ProjectsContext";
import { useTour } from "@reactour/tour";
import { TbBook } from "react-icons/tb";
import { IoIosLink, IoIosStar, IoIosStarOutline } from "react-icons/io";
import { TostMessageContext } from "../../context/TostMessage";
import {login_link_gen} from "../../config/variables/index";
import { FaLink } from "react-icons/fa";

export default function DashboardSideNav() {
  const { userProfile, logOutFunction } = useContext(AuthContext);
  const location = useLocation();
  const [cookies] = useCookies();
  const { setTostMessage } = useContext(TostMessageContext);

  const getLoginLnk = async () => {
    const data = {
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      }
    };
    try {
      const response = await axios.post(login_link_gen, data, {
        headers: {
          access: cookies.urbexUserToken,
        },
      });
      if (response.data.status) {
        setTostMessage({
          messageType: "success",
          message: 'Guest sign-in link copied to clipboard',
        });
        navigator.clipboard.writeText(response.data.response)
      } else {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
      }
    } catch (error) {
      setTostMessage({
        messageType: "error",
        message: "Something went wrong.",
      });
    }
  }

  return (
    <div className='DashboardSideNav' id="tour_side_nav">
      <div className=''>
        <img src={Logo} className='h-20 mb-2 mt-20 mx-auto' alt='logo' />
      </div>

      <div className='h-screen first-step relative mt-4'>
        <ul className='p-0'>
          {DashboardSideNavLinks?.map((link) => {
            const { name, icon, path, id, accountType } = link;
            // const isActive = path == location.pathname;
            function matchRuleShort(str, rule) {
              var escapeRegex = (str) =>
                str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
              return new RegExp(
                "^" + rule.split("*").map(escapeRegex).join(".*") + "$"
              ).test(str);
            }

            // const isActive = path == location.pathname;
            const isActive = matchRuleShort(location.pathname, `${path}*`);
            return (
              <React.Fragment key={id}>
                {userProfile?.account_type.toLowerCase() ===
                  accountType.toLowerCase() || accountType === "both" ? (
                  <NavLink to={path}>
                    <li
                      className={`flex justify-between text-lg h-16 items-center pr-2 pl-4 ${
                        isActive
                          ? "bg-white rounded-tr-full rounded-br-full text-native-blue"
                          : "text-white"
                      }`}
                    >
                      <div className='flex items-center gap-2'>
                        <div className='side-nav-icon text-whi'>{icon}</div>
                        <span className='nav-link-name'>{name}</span>
                      </div>
                      {isActive && (
                        <BsArrowRightShort
                          size={20}
                          className='rounded-full h-10 p-2 w-10 bg-stone-300'
                        />
                      )}
                    </li>
                  </NavLink>
                ) : null}
              </React.Fragment>
            );
          })}
          {userProfile?.account_type == "client" && (
            <li
              onClick={() => getLoginLnk()}
              className={`flex cursor-pointer hover:opacity-[0.7] justify-between text-lg h-16 items-center pr-2 pl-4 text-white`}
            >
              <div className='flex items-center gap-2'>
                <div className='side-nav-icon text-whi'><FaLink /></div>
                <span className='nav-link-name font-semibold'>Guest Invite</span>
              </div>
            
            </li>
          )}
        </ul>
       
        <div
          onClick={() => logOutFunction()}
          className={`flex mt-10 justify-between text-lg h-14 items-center  pl-4 hover:bg-red-500 rounded-tr-full rounded-br-full text-white `}
        >
          <div className='flex items-center gap-2'>
            <BsFillArrowLeftSquareFill />
            <span className='nav-link-name'>LogOut</span>
          </div>
          <div className='arrow-container'>
            <BsArrowLeftShort className='rounded-full h-10 p-2 w-10 bg-white text-black mr-4' />
          </div>
        </div>
      </div>
    </div>
  );
}

export function DashboardTopNav() {
  const { userProfile, logOutFunction } = React.useContext(AuthContext);
  const [toggleNav, setToggleNav] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsOpen } = useTour();
  const {
    projectDetailes,
    getProjectDetailes,
    project,
    getProject,
    setProject,
  } = useContext(ProjectsContext);
  const params = useParams();

  const [cookies] = useCookies();
  const { setTostMessage } = useContext(TostMessageContext);

  const getLoginLnk = async () => {
    const data = {
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      }
    };
    try {
      const response = await axios.post(login_link_gen, data, {
        headers: {
          access: cookies.urbexUserToken,
        },
      });
      if (response.data.status) {
        // navigator.clipboard.writeText(response.data.response)
        navigator.clipboard
          .writeText(response.data.response)
          .then(() => {
            setTostMessage({
              messageType: "success",
              message: 'Guest sign-in link copied to clipboard',
            });
          })
          .catch(() => {
            setTostMessage({
              messageType: "error",
              message: 'Unable to copy guest sign-in link',
            });
          });
      } else {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
      }
    } catch (error) {
      setTostMessage({
        messageType: "error",
        message: "Something went wrong.",
      });
    }
  }

  useEffect(() => {
    getProjectDetailes();
  }, []);
  useEffect(() => {
    getProject(params?.id);
    return () => {
      if (project.data) {
        setProject({
          loading: false,
          data: null,
        });
      }
    };
  }, [params?.id]);

  return (
    <>
      <div className='h-24 flex items-center justify-between border-gray-300 border-b px-6 md:px-10'>
        <div className='flex gap-4 items-center'>
        {location.pathname !== `/dashboard/search-project/${params.id}` && (
          <div
            onClick={() => setToggleNav(true)}
            className='flex cursor-pointer items-center justify-center lg:hidden'
          >
            <AiOutlineMenu size={24} />
          </div>
        )}
          {DashboardSideNavLinks.filter(
            (item) => item.path == location.pathname
          ).map((item, id) => (
            <div key={id}>
              {location.pathname == item.path && (
                <h2 className='text-[20px] font-semibold md:text-xl lg:text-2xl m-0'>
                  {item.name}
                </h2>
              )}
            </div>
          ))}
          <div className='flex items-center gap-2'>
            {location.pathname ===
              `/dashboard/ongoing-projects/${params.id}` && (
              <>
                {/* <img
                  src='/pajamas_project.svg'
                  className='h-6 w-6 hidden md:block'
                  alt='icon'
                /> */}
                <div className='h-10 w-10 block cursor-pointer flex items-center justify-center bg-none md:bg-white rounded-full'>
                  <img
                    src='/left-arrow.svg'
                    className='h-6 w-6 block cursor-pointer'
                    alt='icon'
                    onClick={() => navigate(-1)}
                  />
                </div>
                {/* <h2 className='text-[20px] md:text-xl lg:text-2xl m-0 md:hidden w-[100%] truncate ...'>
                  {projectDetailes?.data?.project_title}
                </h2> */}
                <h2 className='md:d-flex font-semibold text-[20px] md:text-xl lg:text-2xl m-0 max-md:hidden'>
                  {projectDetailes?.data?.project_title}
                </h2>
              </>
            )}{" "}
            {location.pathname === `/dashboard/search-project/${params.id}` && (
              <>
                <div className='h-10 w-10 block cursor-pointer flex items-center justify-center bg-white rounded-full'>
                  <img
                    src='/left-arrow.svg'
                    className='h-6 w-6 block cursor-pointer'
                    alt='icon'
                    onClick={() => navigate(-1)}
                  />
                </div>
                <h2 className='text-[20px] font-semibold md:text-xl cursor-pointer lg:text-2xl m-0 md:hidden w-[100%] truncate ...'>
                  {project?.data?.project_title}
                </h2>
                <h2 className='text-[20px] font-semibold md:text-xl lg:text-2xl m-0 max-md:hidden'>
                  {project?.data?.project_title}
                </h2>
              </>
            )}
            {location.pathname == `/dashboard/activity-tracker` && (
              <>
                <h2 className='text-2xl m-0 md:hidden'>Activity Tracker</h2>
                <h2 className='text-2xl m-0 max-md:hidden'>Activity Tracker</h2>
              </>
            )}
            {location.pathname == `/dashboard/project-gallery` && (
              <>
                <h2 className='text-2xl m-0 md:hidden'>Media Library</h2>
                <h2 className='text-2xl m-0 max-md:hidden'>Media Library</h2>
              </>
            )}
            {location.pathname === `/dashboard/ongoing-projects/${params.id}/subscriptions/${params.id}` && (
              <>
                {/* <img
                  src='/pajamas_project.svg'
                  className='h-6 w-6 hidden md:block'
                  alt='icon'
                /> */}
                <div className='h-10 w-10 block cursor-pointer flex items-center justify-center bg-white rounded-full'>
                  <img
                    src='/left-arrow.svg'
                    className='h-6 w-6 block cursor-pointer'
                    alt='icon'
                    onClick={() => navigate(-1)}
                  />
                </div>
                <h2 className='text-[20px] md:text-xl lg:text-2xl m-0 md:hidden w-[100%] truncate ...'>
                  {projectDetailes?.data?.project_title}
                </h2>
                <h2 className='text-[20px] md:text-xl lg:text-2xl m-0 max-md:hidden'>
                  {projectDetailes?.data?.project_title}
                </h2>
              </>
            )}
          </div>
        </div>
        <div className='flex items-center justify-end gap-4'>
          {location.pathname == `/dashboard/ongoing-projects/${params.id}` ? (
            <Link
              to={`/dashboard/ongoing-projects/${params.id}/subscriptions/${params.id}`}
              className='subscription-button rounded-full px-2  py-1 bg-transparent flex justify-between items-center'
            >
              <a className='hidden md:block subscription-text'>Subscription</a>
              <IoIosStar className="block md:hidden" color="gold" size={20} />
            </Link>
          ) : null}
          {location.pathname == `/dashboard/ongoing-projects` && (
            <>
              {/* <Link
                to={`/dashboard/projects-gallery`}
                className="bg-native-blue rounded-full px-2 md:px-3 text-white py-2 flex justify-between items-center"
              >
                <MdOutlineImage />
                <a className="text-white ml-2">Media Library</a>
              </Link>
              <Link
                to={`/dashboard/activity-tracker`}
                className="bg-native-blue rounded-full px-2 md:px-3 text-white py-2 flex justify-between items-center"
              >
                <TbActivityHeartbeat />
                <a className="text-white ml-2">Activity Tracker</a>
              </Link> */}
            </>
          )}
          {location.pathname == `/dashboard/home` && (
            <div onClick={() => setIsOpen(true)} className='notification-icon-container relative bg-white p-2 rounded-full cursor-pointer'>
              {/* <div className='absolute top-0 right-1 bg-primary p-1 text-xs text-white h-4 w-4 flex items-center justify-center rounded-full'>
                2
              </div>
              <img src='/chat-bubbles.png' className='h-6 w-6' /> */}
              <TbBook className='h-6 w-6' />
            </div>
          )}
          {/* <div className='notification-icon-container relative bg-white p-2 rounded-full cursor-pointer'>
            <span className='absolute top-2 right-2 bg-red-500 h-2 w-2 rounded-full'></span>
            <Icon as={BsBellFill} w={6} h={6} />
          </div> */}
          <div className='hidden md:block'>
            <Avatar
              name={userProfile?.name}
              src={userProfile?.display_picture}
            />
          </div>
        </div>

        <div
          className={`transition-transform duration-500 lg:hidden fixed top-0 bottom-0 right-0 flex-col max-w-full bg-native-blue z-50 h-screen w-full ${
            toggleNav ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div
            onClick={() => setToggleNav(false)}
            tabIndex={0}
            className='btn btn-ghost btn-circle mb-4 mt-3 mr-5 self-end'
          >
            <AiOutlineClose className='h-7 w-7 text-white' />
          </div>
          <ul className='p-0'>
            {DashboardSideNavLinks?.map((link) => {
              const { name, icon, path, id, accountType } = link;
              const isActive =
                path == location.pathname ||
                (`${path}/${params.id}` ==
                  `${location.pathname}/${params.id}` &&
                  true);

              return (
                <React.Fragment key={id}>
                  {(userProfile?.account_type &&
                    userProfile?.account_type.toLowerCase() ===
                      accountType.toLowerCase()) ||
                  accountType === "both" ? (
                    <NavLink to={path} onClick={() => setToggleNav(false)}>
                      <li
                        className={`mr-4 flex justify-between text-lg h-16 items-center pr-2 pl-4 ${
                          isActive
                            ? "bg-white rounded-tr-full rounded-br-full text-native-blue"
                            : "text-white"
                        } `}
                      >
                        <div className='flex items-center gap-2'>
                          <div className='side-nav-icon'>{icon}</div>
                          <span className='nav-link-name'>{name}</span>
                        </div>
                        {isActive && (
                          <BsArrowRightShort
                            size={20}
                            className='rounded-full h-10 p-2 w-10 bg-stone-300'
                          />
                        )}
                      </li>
                    </NavLink>
                  ) : null}
                </React.Fragment>
              );
            })}
            {userProfile?.account_type == "client" && (
              <li
                onClick={() => getLoginLnk()}
                className={`flex cursor-pointer hover:opacity-[0.7] justify-between text-lg h-16 items-center pr-2 pl-4 mr-4 text-white`}
              >
                <div className='flex items-center gap-2'>
                  <div className='side-nav-icon text-white'><FaLink /></div>
                  <span className='nav-link-name font-semibold'>Guest Invite</span>
                </div>
              
              </li>
            )}
          </ul>
          <div
            onClick={() => logOutFunction()}
            className={`flex mt-10 justify-between text-lg h-14 items-center  pl-4 hover:bg-red-500 rounded-tr-full rounded-br-full text-white `}
          >
            <div className='flex items-center gap-2'>
              <BsFillArrowLeftSquareFill />
              <span className='nav-link-name'>LogOut</span>
            </div>
            <div className='arrow-container'>
              <BsArrowLeftShort className='rounded-full h-10 p-2 w-10 bg-white text-black mr-4' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
