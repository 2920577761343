import React from "react";
import "./FAQPage.scss";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";

function FAQPage() {
  // const [bids, setBids] = React.useState({
  //   loading: true,
  //   data: [],
  // });
  // // const [bidsFilter, setBidsFilter] = React.useState({
  // //   filter: false,
  // //   data: null,
  // // });
  // const { userProfile } = React.useContext(AuthContext);
  // const [cookies] = useCookies();

  // async function getBids() {
  //   const data = {
  //     user: {
  //       account_id: userProfile?.account_id,
  //     },
  //     data: {
  //       records: 10,
  //       offset: 0,
  //     },
  //   };
  //   try {
  //     const response = await axios.post(project_bids_end_point, data, {
  //       headers: {
  //         access: cookies.urbexUserToken,
  //       },
  //     });
  //     // return console.log(response.data);
  //     // return response.data;
  //     setBids({
  //       loading: false,
  //       data: response.data.bids,
  //     });
  //   } catch (error) {
  //     // console.log(error);
  //   }
  // }
  // React.useEffect(() => {
  //   getBids();
  // }, []);
  const FAQ = [
    {
      question: "What is Urbex Africa?",
      answer:
        "Urbex Africa is a platform that helps you as an African in Africa or its diaspora safely take-on and monitor your personal building projects from the comfort of your device by equipping you with real-time project updates and access to professional supervision.",
    },
    {
      question: "How does the Urbex App ensure timely delivery?",
      answer:
        "On the App, Your contractor will have access to all the project management tools required to make their job easier in delivering your project. Tools such as Task reminders, material inventories, funds & budget monitoring. Furthermore, Urbex Africa assigns project managers from our network to regularly visit your site for quality inspection and verification.",
    },
    {
      question: "Can I use the Urbex App for an existing building project?",
      answer:
        "Yes, you can! Urbex Africa allows you to onboard ongoing projects. Our team will work with you to assess the current project status and integrate it into the platform, so you can start monitoring progress, managing expenses, and ensuring quality standards right away.",
    },
  ];
  return (
    <div className='FAQPage px-4 lg:py-10 xl:px-20'>
      <h1>Frequently asked questions</h1>
      <Accordion allowToggle>
        {FAQ.map((item, i) => (
          <AccordionItem key={i}>
            <h2>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  {item.question}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>{item.answer}</AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
}

export default FAQPage;
