import React, { useCallback, useState, useContext } from "react";
import { LoginForm } from '../../components/FormsComponent/LoginForm';
import './AuthPage.scss';
import bgImage from '../../assets/images/auth-bg.svg';
import Logo from '../../assets/images/logo2.png';
import {
  auto_login_endpoint
} from "../../config/variables";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "./../../context/AuthContext";
import { TostMessageContext } from "../../context/TostMessage";
import ReactLoading from 'react-loading';

function AutoLoginPage() {
  const { userProfile } = React.useContext(AuthContext);
  const { token, page } = useParams();
  const [loading, setLoading] = useState(true);
  const [cookies] = useCookies();
  const { setTostMessage } = React.useContext(TostMessageContext);
  const { AutoLoginFunction } = useContext(AuthContext);

  React.useEffect(() => {
    setLoading(true);
    console.log(page);

    AutoLoginFunction(token, setLoading, page);
  }, [token])

  return (
    <div className="relative max-h-screen h-full px-4">
      <img
        src={bgImage}
        alt="image"
        className="absolute top-0 right-0 left-0 bottom-0 h-screen object-cover w-full opacity-50"
      />
      <div className="absolute z-10 flex p-3 top-0 right-0 left-0 bottom-0 h-screen object-cover w-full">
        <div className="bg-[#ffffff] shadow-2xl rounded-xl p-6 h-[300px] w-[300px] flex flex-col m-auto items-center justify-evenly">
          <div className="self-center">
            <img src={Logo} className="h-20" alt="logo" />
          </div>
          <div className="mx-auto px-3 py-3">
            <a className="font-semibold text-native-blue">Logging you in</a>
          </div>
          <ReactLoading type={'spokes'} color="#000aff" />
        </div>
      </div>
    </div>
  );
}

export default AutoLoginPage;
